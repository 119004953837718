<template>
  <div class="department-item" :class="{ opened: isOpen }">
    <div class="department-item__head grid gtc-2 ggap-20 ai-c pl-20 pr-20 pt-10 pb-10">
      <FormCheckbox class="fs-16 fw-b" :label="item.name" :defaultValue="isSelected" v-model="isSelected" />

      <div v-if="users.length" class="flex ai-c ggap-10">
        <div v-if="!isOpen" class="flex ai-c">
          <div v-for="user of users" :key="user.id" class="department-item__user cover ava-30">
            <img :src="user.avatar || require('@/assets/img/ava.svg')" />
          </div>
          <small v-if="item.users.length > 5" class="fs-12 ml-15"> Всего: {{ item.users.length }} </small>
        </div>
        <button
          class="btn transparent-grey sm pl-10 pr-10 ml-auto"
          :class="{ active: isOpen }"
          @click="isOpen = !isOpen"
        >
          <BaseIcon class="ic-16 black" :icon="isOpen ? 'arrow-down' : 'arrow-right'" />
        </button>
      </div>
      <div v-else class="t-grey-dark">&mdash;</div>
    </div>

    <!-- Body -->
    <div v-if="isOpen" class="department-item__body flex fw ggap-5 pl-20 pr-20 pt-10 pb-10">
      <ItemUser
        v-for="user of item.users"
        :key="user.id"
        :item="{
          id: user.id,
          avatar: user.avatar,
          text: user.fullname,
          position: user.specialization || 'Без должности'
        }"
        :isRemove="false"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, computed, ref, watch } from 'vue'
import { BaseIcon, FormCheckbox, ItemUser } from '@/components'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  modelValue: {
    type: Array,
    default: () => []
  }
})

// Data
const { item, modelValue } = toRefs(props)
const isSelected = ref(false)
const isOpen = ref(false)

// Computed
const users = computed(() => (item.value?.users?.length ? item.value.users.slice(0, 5) : []))
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Watch
watch(isSelected, (val) => {
  const index = propValue.value.findIndex((el) => el.id === item.value.id)
  if (val) {
    if (index === -1) propValue.value.push(item.value)
  } else {
    if (index !== -1) propValue.value.splice(index, 1)
  }
})

// Created
isSelected.value = propValue.value.findIndex((el) => el.id === item.value.id) !== -1
</script>

<style lang="scss" scoped>
.department-item {
  &__user {
    margin-right: -5px;
    box-shadow: 0 0 0 2px var(--box);
  }

  &__head,
  &__body {
    border-top: var(--b1);
  }

  &__body {
    background-color: var(--form);
  }

  &:first-child > &__head {
    border: 0;
  }

  &.opened > &__head {
    color: var(--primary);
  }
}
</style>
