<template>
  <div class="edit-departments grid ggap-10">
    <!-- Selected departments -->
    <template v-if="propValue && propValue.length">
      <template v-if="currentTab && currentTab === 'agreementers'">
        <Draggable v-model="propValue" item-key="id" ghost-class="ghost" handle=".handle" class="grid ggap-10">
          <template #item="{ element, index }">
            <ItemDepartmentDrag
              :length="index > 0 ? getFullLength(index) : 0"
              :item="element"
              @deleteAction="deleteAction"
            />
          </template>
        </Draggable>
      </template>
      <template v-else>
        <ItemDepartment
          v-for="department of propValue"
          :key="department.id"
          :item="department"
          @deleteAction="deleteAction"
        />
      </template>
    </template>
    <BaseAlert v-else type="yellow"> Нет ни одного отдела, нажмите кнопку выбрать отделы!</BaseAlert>

    <!-- Btn new department -->
    <button class="btn primary-light jc-c" type="button" @click.stop.prevent="isDepartment = true">
      <BaseIcon class="ic-16 primary" icon="plus-circle" />
      Выбрать отделы
    </button>

    <!-- Select departments -->
    <ModalWrapper v-if="isDepartment" @close="isDepartment = false">
      <!-- Head -->
      <template #head>
        <div class="flex ai-c ggap-20">
          <h1 class="title">Выбор отделов</h1>
          <FormInput class="sm" label="" placeholder="Поиск..." type="search" v-model="searchText" />
        </div>
      </template>

      <!-- Body -->
      <template #default>
        <div class="edit-departments__list flex fd-c pos-r">
          <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white-transparent" />
          <template v-if="optionsDepartments && optionsDepartments.length">
            <ItemCheck
              v-for="department of optionsDepartments"
              :key="department.id"
              :item="department"
              v-model="selectedDepartments"
            />
          </template>
          <div v-else class="p-20">
            <BaseAlert type="yellow"> Нет ни одного отдела!</BaseAlert>
          </div>
        </div>
      </template>

      <template #foot>
        <div class="flex ai-c jc-sb">
          <button class="btn cancel" @click="cancelModel">Отмена</button>
          <button class="btn primary" :disabled="!selectedDepartments.length" @click="saveModel">
            Готово
            <template v-if="selectedDepartments.length">
              : выбранно
              <small class="marker secondary">{{ selectedDepartments.length }}</small>
            </template>
          </button>
        </div>
      </template>
    </ModalWrapper>
  </div>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, ref, computed, watch } from 'vue'
import { BaseLoad, BaseIcon, BaseAlert, ModalWrapper, FormInput } from '@/components'
import ItemDepartment from './ItemDepartment.vue'
import ItemDepartmentDrag from './ItemDepartmentDrag.vue'
import ItemCheck from './ItemCheck.vue'

// Emits
const emits = defineEmits(['update:departments'])

// Props
const props = defineProps({
  currentTab: {
    type: String,
    default: ''
  },
  departments: {
    type: Array,
    default: () => []
  }
})

// Data
const { currentTab, departments } = toRefs(props)
const store = useStore()
const isLoad = ref(false)
const isDepartment = ref(false)
const selectedDepartments = ref([])
const searchText = ref('')

// Computed
const propValue = computed({
  get: () => departments.value,
  set: (val) => emits('update:departments', val)
})
const optionsDepartments = computed(() => {
  const departmentsStore = store.getters['helpers/departments'] || []
  const res = departmentsStore.map((el) => {
    const depart = propValue.value.find((dep) => el.id === dep.id)
    return {
      ...el,
      manual_users: depart?.manual_users.map((user) => user) || []
    }
  })

  return searchText.value ? res.filter((el) => el.name.toLowerCase().includes(searchText.value.toLowerCase())) : res
})

// Watch
watch(isDepartment, (val) => {
  if (val) selectedDepartments.value = cloneDeep(propValue.value)
})

// Created
getData()

// Methods
async function getData() {
  isLoad.value = true
  await store.dispatch('helpers/GET_DEPARTMENTS')
  isLoad.value = false
}
async function cancelModel() {
  selectedDepartments.value = cloneDeep(propValue.value)
  isDepartment.value = false
}
async function saveModel() {
  propValue.value = cloneDeep(selectedDepartments.value)
  isDepartment.value = false
}
async function deleteAction(departmentId) {
  const index = propValue.value.findIndex((el) => el.id === departmentId)
  if (index !== -1) {
    propValue.value.splice(index, 1)
    cancelModel()
  }
}
function getFullLength(index) {
  let res = 0
  for (let i = 0; i < index; i++) {
    res = (departments.value[i]?.users?.length || 0) + (departments.value[i]?.manual_users?.length || 0) + res
  }
  return res
}
</script>

<style lang="scss" scoped>
.edit-departments {
  &__list {
    width: 700px;
    min-height: 50vh;
  }
}
</style>
